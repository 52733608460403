import htmlKeyboardResponse from "@jspsych/plugin-html-keyboard-response";
import jsPsychInstructionsResponse from "@jspsych/plugin-instructions";

import { KEY_CODES } from "../../gearshiftGame/constants";
import { Game } from "../../gearshiftGame/game";
import { Score } from "../../gearshiftGame/score";
import { createStimulus } from "../../gearshiftGame/utils";
import { getGearshiftMissionConfig, instructionImage } from "../../lib/utils";
import { buildFeedbackTrial } from "../trials/feedback";
import { buildInstructionsTrial } from "../trials/instructions";
import { buildSkipMissionProcedure } from "./skipMission";

/**
 * @type {number}
 * @description The current mission
 */
const MISSION_NUMBER = 4;

/**
 * @type {Score}
 * @description The user's score
 * NOTE: Used here so we don't have to pass it through every single function
 */
let SCORE;

/**
 * Builds the first mission of the game
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @returns The timeline object for the first mission
 */
export async function buildMissionFour(jsPsych, metadata) {
  // Check if a config file was given for this mission
  let config;
  try {
    config = await getGearshiftMissionConfig(jsPsych, metadata, MISSION_NUMBER);
  } catch (e) {
    console.error(`Unable to load mission config file for mission ${MISSION_NUMBER}`);
  }

  // No config file was given for mission 1, skip this mission (no file given or failed to load the file)
  if (!config) return buildSkipMissionProcedure(MISSION_NUMBER);

  // Config file given - build the mission!
  console.log("Mission Four Configuration:", config);

  // Initialize variables shared across the mission
  const { missionLives, missionNumTrials, trials, thisFile } = config;
  SCORE = new Score(
    jsPsych,
    missionLives, // # of lives to start the mission with
    metadata.rewardReductionByReset // Amount to reduce score by when the user runs out of lives
  );

  // Build the timeline from the config's trials array
  const missionFourTimeline = trials.map((trialConfig) => {
    const stimulus = createStimulus(MISSION_NUMBER, missionNumTrials, trialConfig);
    switch (trialConfig.trialType) {
      case "INSTRUCTION":
        // NOTE: Instructions trial is the first trial of the mission
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildMissionFourInstructions(jsPsych, trialConfig);
      case "TASK":
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildMissionFourTask(jsPsych, metadata, trialConfig, stimulus);
      case "MESSAGE":
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildMessageTrial(jsPsych, metadata, trialConfig, stimulus);
      case "FEEDBACK":
        // NOTE: Feed back trial is the last trial of the mission
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildFeedbackTask(jsPsych, trialConfig);
      default:
        throw new Error("An unknown trial type was found:", trialConfig.trialType);
    }
  });

  return {
    data: {
      missionNumber: MISSION_NUMBER,
      missionFile: thisFile,
      missionNumTrials,
      skipped: false,
    },
    save_trial_parameters: { stimulus: false },
    timeline: missionFourTimeline,
  };
}

/**
 * Builds the trial for displaying the instructions for mission one
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} trialConfig The configuration object for a specific trial
 * @param {Score} score The user's current score
 * @returns
 */
function buildMissionFourInstructions(jsPsych, trialConfig) {
  return {
    data: { trialConfig },
    ...buildInstructionsTrial(jsPsych, [
      instructionImage("assets/images/instructions/m4/instructions 4.1.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.2.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.3.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.4.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.5.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.6.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.7.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.8.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.9.svg"),
      instructionImage("assets/images/instructions/m4/instructions 4.10.svg"),
    ]),
    on_finish: (data) => SCORE.assignScore(data),
  };
}

/**
 * Builds the trial needed for mission one's task
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} stimulus The HTML stimulus to show
 * @param {Score} score The user's current score
 * @returns
 */
function buildMissionFourTask(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);
  return {
    type: htmlKeyboardResponse,
    stimulus,
    data: { trial_config: trialConfig },
    timeline: [
      //Phase 0: Board initializes, end of phase 0 is when target car appears
      {
        choices: "NO_KEYS",
        trial_duration: newGame.targetLaunchDelay,
        on_load: () => newGame.startPhase0(),
        on_finish: (data) => {
          data.name = "initializeTarget";
          data.driverSpeed = newGame.driverCarSpeed;
          data.distanceToTarget = newGame.driverDistanceToTarget;
          data.trialStart = {
            name: "trialStart",
            driverSpeed: newGame.driverCarSpeed,
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
          };
          SCORE.assignScore(data);
        },
      },

      // Phase 0.1 of mission4
      // User needs to choose between two target lanes, len3 or lane5
      // Will remain in this phase until the user makes a decision
      {
        choices: ["f", "k"],
        on_load: () => newGame.startPhase0_1M4(),
        on_finish: (data) => {
          const key = jsPsych.data.get().last(1).values()[0].response;
          console.log("PHASE 0.1", key, data.response);
          data.keyCode = KEY_CODES[key];
          newGame.phase0ChosenLane = data.keyCode;
          SCORE.assignScore(data);
        },
      },

      // Phase 0.2 of mission4
      // Given user choice, driver truck will move left/right into that lane.
      // Phase simply plays the animation
      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase0_2M4(jsPsych.data.get().last(1).values()[0].keyCode),
        on_finish: (data) => {
          SCORE.assignScore(data);
        },
      },

      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase0_3M4(jsPsych.data.get().last(2).values()[0].keyCode),
        on_finish: (data) => {
          if (data.crashed) {
            jsPsych.endCurrentTimeline();
          } else data.crashed = false;
          SCORE.assignScore(data);
        },
      },

      // Phase 2: Start of CSI period, triggered by proximity zone initiation in previous phase
      // Terminates when CSI is over and it's time to show the license
      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase2M4(),
        on_finish: (data) => {
          data.name = "licensePlateOn";
          data.timestamp = jsPsych.data.get().last(2).values()[0].time_elapsed;
          data.driverSpeed = newGame.driverCarSpeed;
          data.distanceToTarget = jsPsych.data.get().last(2).values()[0].distanceToTarget;
          SCORE.assignScore(data);
        },
      },

      // Phase 3: At the start of this phase, the license is displayed
      // Relies on the user to press one of the 6 correct key lanes to trigger the end of Phase 3
      {
        choices: "NO_KEYS", // NOTE: Keyboard events are handled inside Game
        on_load: () => newGame.startPhase3M4(),
        on_finish: (data) => {
          data.name = "responseKeyPressed";
          data.keyCode = KEY_CODES[data.response] ?? null; // keyCode is null if no response is given
          data.responseTime =
            data.time_elapsed - jsPsych.data.get().last(2).values()[0].time_elapsed;
          data.driverSpeed = newGame.driverCarSpeed;

          // Crash - move on to the next timeline (e.g. skip the rest of this Game's phases)
          // if (data.crashed) jsPsych.endCurrentTimeline();
          // else data.crashed = false;

          SCORE.assignScore(data);
        },
      },

      // Phase 4: Once lane has been chosen in phase 3, phase 4 begins with the driver car moving to the user chosen lane
      // Terminates when the driver car reaches the chosen lane.
      {
        choices: "NO_KEYS",
        // Start the phase based on the lane chosen
        on_load: () => newGame.startPhase4(jsPsych.data.get().last(1).values()[0].keyCode),
        on_finish: (data) => {
          data.name = "driverInResponseLane";
          data.driverSpeed = newGame.driverCarSpeed;
          SCORE.assignScore(data);
        },
      },

      //Phase 5
      // Once driver is in chosen lane, the package will appear and travel down the correct lane. Simultaneously, the target car also moves down the highway until it disappears.
      // Once the package reaches the end of the highway, a score will show above the driver car, showing the points the driver car received in this game trial.
      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase5(),
        on_finish: (data) => {
          data.name = "packageDisappears";
          data.driverSpeed = newGame.driverCarSpeed;
          data.emotion = newGame.emotion;
          data.packageInTargetLane = {
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
            driverSpeed: newGame.driverCarSpeed,
            packageDistance: newGame.packageDistanceToTarget,
          };
          data.targetDisappears = {
            timestamp: newGame.targetCarDisappearTimestamp,
            driverSpeed: newGame.driverCarSpeed,
          };
          data.emotionReaction = {
            emotion: newGame.emotion,
            driverSpeed: newGame.driverCarSpeed,
            timestamp:
              jsPsych.data.get().last(1).values()[0].time_elapsed - newGame.feedbackDuration,
          };

          SCORE.assignScore(data);
        },
      },

      // Phase 6: Headquarter Survey
      {
        on_load: () => newGame.startHeadquarterSurveyPhase(),
        choices: ["s", "d", "f", "k", "l", ";"],
        trial_duration: newGame.headquarterMessageDuration,
        on_finish: (data) => {
          data.name = "messageFromHQ";
          SCORE.assignScore(data);
        },
      },

      // Phase 7: Driver car travels back to the center of the highway
      // Triggered once user completes survey
      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase7(),
        on_finish: (data) => {
          data.name = "driverAtCenter";
          data.driverToCenter = {
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
            driverSpeed: newGame.driverCarSpeed,
          };
          data.driverSpeed = newGame.driverCarSpeed;
          SCORE.assignScore(data);
        },
      },
    ],
  };
}

/**
 * Builds the trial needed to display the headquarter message
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} stimulus The HTML stimulus to show
 * @param {Score} score The user's current score
 * @returns
 */
// TODO: Can this be pulled into its file? Is it used in other missions?
function buildMessageTrial(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);
  return {
    type: htmlKeyboardResponse,
    stimulus,
    data: { trialConfig },
    choices: [" "],
    on_load: () => {
      // Initialize board and display the agent file
      newGame.startPhase0();
      newGame.startPhase6();
    },
    on_finish: (data) => SCORE.assignScore(data),
  };
}

/**
 * Builds the trial needed for mission one's feedback
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {Score} score The user's current score
 * @returns
 */
function buildFeedbackTask(jsPsych, trialConfig) {
  return {
    data: { trialConfig },
    timeline: [
      // Display the total rewards
      buildFeedbackTrial(SCORE, "assets/images/postgame/FeedbackScreen_Mission4.svg"),
      // Display the user's achievements
      {
        type: jsPsychInstructionsResponse,
        data: { name: "achievements" },
        pages: [instructionImage("assets/images/postgame/achievementScreen_mission4.svg")],
      },
    ],
  };
}
