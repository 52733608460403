import htmlKeyboardResponse from "@jspsych/plugin-html-keyboard-response";
import jsPsychInstructionsResponse from "@jspsych/plugin-instructions";

import { KEY_CODES } from "../../gearshiftGame/constants";
import { Game } from "../../gearshiftGame/game";
import { Score } from "../../gearshiftGame/score";
import { createStimulus } from "../../gearshiftGame/utils";
import { getGearshiftMissionConfig, instructionImage } from "../../lib/utils";
import { buildFeedbackTrial } from "../trials/feedback";
import { buildInstructionsTrial } from "../trials/instructions";
import { buildSkipMissionProcedure } from "./skipMission";

/**
 * @type {number}
 * @description The current mission
 */
const MISSION_NUMBER = 5;

/**
 * @type {Score}
 * @description The user's score
 * NOTE: Used here so we don't have to pass it through every single function
 */
let SCORE;

/**
 * Builds the first mission of the game
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @returns The timeline object for the first mission
 */
export async function buildMissionFive(jsPsych, metadata) {
  // Check if a config file was given for this mission
  let config;
  try {
    config = await getGearshiftMissionConfig(jsPsych, metadata, MISSION_NUMBER);
  } catch (e) {
    console.error(`Unable to load mission config file for mission ${MISSION_NUMBER}`);
  }

  // No config file was given for mission 1, skip this mission (no file given or failed to load the file)
  if (!config) return buildSkipMissionProcedure(MISSION_NUMBER);

  // Config file given - build the mission!
  console.log("Mission Five Configuration", config);

  // Initialize variables shared across the mission
  const { missionLives, missionNumTrials, trials, thisFile } = config;
  SCORE = new Score(
    jsPsych,
    missionLives, // # of lives to start the mission with
    metadata.rewardReductionByReset // Amount to reduce score by when the user runs out of lives
  );

  // Build the timeline from the config's trials array
  const missionFiveTimeline = trials.map((trialConfig) => {
    const stimulus = createStimulus(MISSION_NUMBER, missionNumTrials, trialConfig);
    switch (trialConfig.trialType) {
      case "INSTRUCTION":
        // NOTE: Instructions trial is the first trial of the mission
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildMissionFiveInstructions(jsPsych, trialConfig);
      case "TASK":
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildMissionFiveTask(jsPsych, metadata, trialConfig, stimulus);
      case "MESSAGE":
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildMessageTrial(jsPsych, metadata, trialConfig, stimulus);
      case "FEEDBACK":
        // NOTE: Feed back trial is the last trial of the mission
        // return SKIP_TRIAL; // TEMP: Skip the trial for testing purposes
        return buildFeedbackTask(jsPsych, trialConfig);
      default:
        throw new Error("An unknown trial type was found:", trialConfig.trialType);
    }
  });

  return {
    data: {
      missionNumber: MISSION_NUMBER,
      missionFile: thisFile,
      missionNumTrials,
      skipped: false,
    },
    save_trial_parameters: { stimulus: false },
    timeline: missionFiveTimeline,
  };
}

/**
 * Builds the trial for displaying the instructions for mission five
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} trialConfig The configuration object for a specific trial
 * @param {Score} score The user's current score
 * @returns
 */
function buildMissionFiveInstructions(jsPsych, trialConfig) {
  return {
    data: { trialConfig },
    ...buildInstructionsTrial(jsPsych, [
      instructionImage("assets/images/instructions/m5/instructions 5.1.svg"),
      instructionImage("assets/images/instructions/m5/instructions 5.2.svg"),
      instructionImage("assets/images/instructions/m5/instructions 5.3.svg"),
      instructionImage("assets/images/instructions/m5/instructions 5.4.svg"),
      instructionImage("assets/images/instructions/m5/instructions 5.5.svg"),
      instructionImage("assets/images/instructions/m5/instructions 5.6.svg"),
      instructionImage("assets/images/instructions/m5/instructions 5.7.svg"),
    ]),
    on_finish: (data) => SCORE.assignScore(data),
  };
}

/**
 * Builds the trial needed for mission five's task
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} stimulus The HTML stimulus to show
 * @param {Score} score The user's current score
 * @returns
 */
function buildMissionFiveTask(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);

  const moveTruckPhase = {
    timeline: [
      //conditional phase where cooperate == 1
      //move truck from left lane to center lane
      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase0_2M5(),
        on_finish: (data) => {
          SCORE.assignScore(data);
        },
      },
    ],
    conditional_function: () => {
      return newGame.cooperating === 1;
    },
  };

  const choosePartnerLanePhase = {
    timeline: [
      //conditional phase cooperate == true
      //propose lane partner shows up in license window
      //now user chooses a lane for its partner.
      {
        choices: ["s", "d", "f", "k", "l", ";"],
        on_load: () => newGame.startPhase0_4M5(),
        on_finish: (data) => {
          data.partnerKeyCode = KEY_CODES[data.response] ?? null;
          SCORE.assignScore(data);
        },
      },
      {
        choices: "NO_KEYS",
        trial_duration: 1000,
        on_load: () => {
          const lastTrialData = jsPsych.data.get().last(1).values()[0];
          const partnerKeyCode = lastTrialData.partnerKeyCode;
          newGame.startPhase0_4BM5(partnerKeyCode);
        },
      },
    ],
    conditional_function: () => {
      return newGame.cooperating === 1;
    },
  };

  return {
    type: htmlKeyboardResponse,
    stimulus,
    data: { trialConfig },
    timeline: [
      //Phase 0: Board initializes, end of phase 0 is when target car appears
      {
        choices: "NO_KEYS",
        trial_duration: newGame.targetLaunchDelay,
        on_load: () => newGame.startPhase0(),
        on_finish: (data) => {
          data.name = "initializeTarget";
          data.driverSpeed = newGame.driverCarSpeed;
          data.distanceToTarget = newGame.driverDistanceToTarget;
          data.trialStart = {
            name: "trialStart",
            driverSpeed: newGame.driverCarSpeed,
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
          };
          SCORE.assignScore(data);
        },
      },

      //Show two target cars, truck, driver
      //user will decide whether to cooperate or not
      //User chooses either to cooperate or not, by pressing either "z" or "/"
      {
        choices: ["z", "/"],
        on_load: () => newGame.startPhase0_1M5(),
        on_finish: (data) => {
          if (data.response === "/") {
            // "/" choice means cooperate
            newGame.cooperating = 1;
          } else {
            newGame.cooperating = 0;
          }
          SCORE.assignScore(data);
        },
      },

      moveTruckPhase,

      //conditional, only happens if user chooses to cooperate
      choosePartnerLanePhase,

      //for both phases where cooperate == true or cooperate == false
      //values will be shown on left/right of each target car
      //user chooses lane for driver
      {
        choices: ["s", "d", "f", "k", "l", ";"],
        on_load: () => newGame.startPhase0_3M5(),
        on_finish: (data) => {
          data.userKeyCode = KEY_CODES[data.response] ?? null; // keyCode is null if no response is given
          SCORE.assignScore(data);
        },
      },

      //for both phases where cooperate == true or cooperate == false
      //for cooperate == true
      //now that two lanes are chosen, 1 for driver and 1 for partner
      //move driver car to chosen lane
      //move truck to partner lane

      //for cooperate == false
      //just move driver to chosen lane
      {
        choices: "NO_KEYS",
        on_load: () => {
          let laneCodes;
          if (newGame.cooperating === 1) {
            // Get the last 2 trials
            const lastTrialData = jsPsych.data.get().last(3).values();
            laneCodes = [lastTrialData[2].userKeyCode, lastTrialData[0].partnerKeyCode];
          } else {
            // Get the last trial
            const lastTrialData = jsPsych.data.get().last(1).values();
            const userKeyCode = lastTrialData[0].userKeyCode;
            laneCodes = [userKeyCode];
          }
          newGame.startPhase0_5M5(laneCodes);
        },
        on_finish: (data) => {
          SCORE.assignScore(data);
        },
      },

      {
        choices: "NO_KEYS",
        on_load: () => {
          let laneCodes;
          if (newGame.cooperating === 1) {
            // Get the last 2 trials
            const lastTrialData = jsPsych.data.get().last(4).values();
            laneCodes = [lastTrialData[2].userKeyCode, newGame.moveTruckLane];
          } else {
            // Get the last trial
            const lastTrialData = jsPsych.data.get().last(2).values();
            const userKeyCode = lastTrialData[0].userKeyCode;
            laneCodes = [userKeyCode];
          }
          newGame.startPhase0_6M5(laneCodes);
        },
        on_finish: (data) => {
          SCORE.assignScore(data);
        },
      },
      // Phase 6: Headquarter Survey
      {
        on_load: () => {
          newGame.startHeadquarterSurveyPhase("phase6");
        },
        choices: ["s", "d", "f", "k", "l", ";"],
        trial_duration: newGame.headquarterMessageDuration,
        on_finish: (data) => {
          data.name = "messageFromHQ";
          SCORE.assignScore(data);
        },
      },
      // Phase 7: Driver car travels back to the center of the highway
      // Triggered once user completes survey
      {
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase7(),
        on_finish: (data) => {
          data.name = "driverAtCenter";
          data.driverToCenter = {
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
            driverSpeed: newGame.driverCarSpeed,
          };
          data.driverSpeed = newGame.driverCarSpeed;
          SCORE.assignScore(data);
        },
      },
    ],
  };
}

/**
 * Builds the trial needed to display the headquarter message
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} stimulus The HTML stimulus to show
 * @param {Score} score The user's current score
 * @returns
 */
// TODO: Can this be pulled into its file? Is it used in other missions?
function buildMessageTrial(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);
  return {
    type: htmlKeyboardResponse,
    stimulus,
    data: { trialConfig },
    choices: [" "],
    on_load: () => {
      // Initialize board and display the agent file
      newGame.startPhase0();
      newGame.startPhase6();
    },
    on_finish: (data) => SCORE.assignScore(data),
  };
}

/**
 * Builds the trial needed for mission one's feedback
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {Score} score The user's current score
 * @returns
 */
function buildFeedbackTask(jsPsych, trialConfig) {
  return {
    data: { trialConfig },
    timeline: [
      // Display the total rewards
      buildFeedbackTrial(SCORE, "assets/images/postgame/FeedbackScreen_Mission5.svg"),
      // Display the user's achievements
      {
        type: jsPsychInstructionsResponse,
        data: { name: "achievements" },
        pages: [instructionImage("assets/images/postgame/achievementScreen_mission5.svg")],
      },
    ],
  };
}
